<template>
  <Card>
    <div class="my-4">
      <h4 class="font-semibold text-lg text-black">
        Work Information
        <span
          class="text-xs refunded ml-2"
          v-if="employmentData.verificationStatus !== 'APPROVED' && requestMethod === 'put'"
        >
          Verification
          {{ employmentStatus(employmentData) }}!</span
        >
      </h4>
      <p class="text-grey text-sm">Personalize your settings</p>

      <!-- <div>
        <SecondaryButton
          width="w-32"
          :text="employmentAvailable ? 'Change work info' : 'Add work Info'"
          :loading="updateLoading"
          @click="
            employmentAvailable ? (showVerificationModal = true) : proceed()
          "
        />
      </div> -->
    </div>

    <div v-if="loading" key="load">
      <div v-for="i in 4" :key="i" class="mt-8">
        <Skeleton height="0.8rem" width="4rem" />
        <Skeleton width="40%" height="1rem" class="mt-2" />
      </div>
    </div>
    <div v-show="!loading" key="data">
      <EmptyState
        description="You don't have any employment data yet"
        class="mt-8"
        v-if="!employmentAvailable"
      />
      <div v-show="employmentAvailable">
        <transition name="slideX" mode="out-in">
          <div
            v-if="
              employmentData.verificationType === 'DOCUMENT' &&
              employmentData.verificationStatus === 'PENDING'
            "
            class="py-2 text-center my-3 mx-auto rounded-lg text-sm w-full md:w-6/12"
            style="color: #523f14; background: #fff0cc"
          >
            <span>We are still verifying your document(s)</span>
          </div>
          <div
            v-else-if="
              employmentData.verificationType === 'DOCUMENT' &&
              employmentData.verificationStatus === 'DECLINED'
            "
            class="p-2 text-center my-3 mx-auto rounded-lg text-sm w-full md:w-10/12"
            style="color: #ff5454; background: #ffe8e8"
          >
            <span> {{ documents.refusalReason }}</span>
          </div>
        </transition>
        <div class="mt-8 md:grid grid-cols-3 py-2">
          <p class="text-grey text-sm">PLACE OF WORK</p>
          <p class="capitalize">
            {{ employmentData.employerName }}
          </p>
        </div>
        <Hr />

        <div>
          <div
            class="md:grid grid-cols-3 py-2"
            v-if="employmentData.verificationType === 'WORK_EMAIL'"
          >
            <p class="text-grey text-sm">WORK EMAIL</p>
            <p class="lowercase">
              {{ workEmail }}
            </p>
          </div>
          <Hr v-if="employmentData.verificationType === 'WORK_EMAIL'" />
          <div class="md:grid grid-cols-3 py-2">
            <p class="text-grey text-sm">WORK ADRRESS</p>
            <p class="capitalize">
              {{ employmentData.officeAddress }}
            </p>
          </div>
          <Hr />
          <div
            class="md:grid grid-cols-3 py-2"
            v-if="employmentData.additionalAddressDetails"
          >
            <p class="text-grey text-sm">MORE DETAILS</p>
            <p class="capitalize">
              {{ employmentData.additionalAddressDetails }}
            </p>
          </div>
          <Hr v-if="employmentData.additionalAddressDetails" />
          <div class="md:grid grid-cols-3 py-2">
            <p class="text-grey text-sm">STATE</p>
            <p class="capitalize">
              {{ employmentData.state }}
            </p>
          </div>
          <Hr />
          <div class="md:grid grid-cols-3 py-2">
            <p class="text-grey text-sm">LGA</p>
            <p class="capitalize">
              {{ employmentData.lga }}
            </p>
          </div>
          <Hr />

          <div v-if="employmentData.verificationType === 'DOCUMENT'">
            <div
              class="md:grid grid-cols-3 py-2"
              v-for="(document, index) in documents.documents"
              :key="document.id"
            >
              <p class="text-grey text-sm">DOCUMENT #{{ index + 1 }}</p>
              <p class="capitalize">
                {{ document.type }}
              </p>
            </div>
            <!-- <div v-for="(document, index) in documents" :key="document.id">
            <Input
              width="w-full"
              :placeholder="`Document #${index + 1}`"
              disabled
              class="mt-8"
              :value="document.type"
            />
          </div> -->
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-3">
        <SecondaryButton
          color="text-white"
          background="bg-brandPurple"
          fontSize="text-sm"
          :text="employmentAvailable ? 'Change work info' : 'Add work Info'"
          :loading="updateLoading"
          @click="
            employmentAvailable ? (showVerificationModal = true) : proceed()
          "
        />
      </div>
    </div>

    <!-- modal for otp -->
    <EmploymentOtp
      :display="showOtp"
      @close="showOtp = false"
      @completed="handleOtpComplete"
    />

    <!-- Modal to update work info? -->
    <Modal
      :display="showVerificationModal"
      @close="showVerificationModal = false"
    >
      <h2 class="-mt-6 text-xl font-bold primary-text w-10/12">
        Do you want to change your work info?
      </h2>
      <p class="text-grey py-3">
        This action will require you to input your work information again.
      </p>
      <Button
        text="Yes, proceed"
        :shadow="true"
        width="w-full"
        p="px-3 py-4"
        class="mt-6 place-self-end"
        @click="proceed()"
      />

      <SecondaryButton
        text="Cancel"
        width="w-full"
        p="px-3 py-4"
        background="bg-transparent"
        class="mt-2 place-self-end font-semibold"
        color="text-dark"
        fontSize="text-base"
        @click="showVerificationModal = false"
      />
    </Modal>

    <!-- Modal to update work info? -->
    <LargeModal
      size="large"
      :display="updateWorkInfo"
      @close="close"
      background="#ffffff"
      padding="px-6 pt-6"
    >
      <UpdateWorkInfo
        v-if="updateWorkInfo"
        @email="updateEmploymentEmailDetails"
        @close="close"
        @documents="updateEmploymentDocumentDetails"
      />
    </LargeModal>
  </Card>
</template>
<script>
  import { mapActions , mapState} from "vuex";
  import { Button, SecondaryButton } from "@/UI/Button";
  import { SUBMIT_EMPLOYMENT_OTP } from "@/utils/api/userInfo.js";
  import {
    GET_WORK_EMAIL,
    GET_WORK_EMPLOYMENT_DOCUMENTS,
  } from "@/utils/api/verification.js";
  import Hr from "@/UI/Hr";
  import { Input, Select } from "@/UI/Input";
  import UpdateWorkInfo from "./UpdateWorkInfo.vue";
  import Location from "@/database/location.json";
  import { Modal, LargeModal } from "@/UI/Modals";
  export default {
    components: {
      Button,
      Hr,
      Input,
      Select,
      Modal,
      LargeModal,
      SecondaryButton,
      EmptyState: () => import("@/UI/EmptyState"),
      EmploymentOtp: () => import("./Otp"),
      Spinner: () => import("@/UI/Spinner"),
      // dangerous syntax it has another data state with same spelling ; just different casing
      UpdateWorkInfo,
    },
    data: () => ({
      workEmailLoading: false,
      showVerificationModal: false,
      updateWorkInfo: false,
      // employmentData: {},
      // workEmail: "",
      // documents: [],
      location: Location,
      lga: [],
      disableInput: true,
      updateLoading: false,
      loading: false,
      showOtp: false,
      resendLoading: false,
      otpLoading: false,
      otp: "",
      // employmentAvailable: false,
      // showForm: false,
      // requestMethod: 'post'
    }),
    computed: {
      ...mapState({
        employmentData: (state) => state.userInfo.userEmployementInfo,
      }),
      employmentAvailable(){
        return Object.keys(this.employmentData).length
      },
      requestMethod(){
        return this.employmentAvailable ? 'put' : 'post'
      },
      workEmail(){
        return this.employmentData?.employmentEmailAddress.workEmail
      },
      documents(){
        return this.employmentData?.employmentDocument
      },
      states() {
        return this.location.map((location) => location.state);
      },
    },
    watch: {
      "employmentData.state"(value) {
        this.location.forEach((state) => {
          if (state.state.name === value) {
            this.lga = state.state.locals;
          }
        });
      },
    },
    beforeMount() {
      // this.loading = true;
      if(Object.keys(this.employmentData).length === 0){
        this.getEmploymentInfo();
      }
    },
    methods: {
      ...mapActions("userInfo", [
        "employmentInfo",
        "addEmploymentInfo",
        "resendEmploymentOtp",
      ]),
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("notification", ["showAlert", "showToast"]),
      ...mapActions('dashboard', ['getDashboardData']),
      getEmploymentInfo() {
        this.employmentInfo()
          .then(() => {
            // this.requestMethod = 'put';
            // this.isAddressValid = true;
            // this.employmentAvailable = true;
            // this.showForm = true;
            // const data = res.data.data;
            // this.employmentData = data;
            // console.log(this.userEmployementInfo, 'emppluyment ingo')
            // if (data.verificationType === "WORK_EMAIL") {
            //   this.workEmail = res.data.data.employmentEmailAddress.workEmail;
            // } else if (data.verificationType === "DOCUMENT") {
            //   this.documents = res.data.data.employmentDocument;
            // }

            this.loading = false;
            // if (
            //   res.data.data.verificationType === "WORK_EMAIL" &&
            //   res.data.data.verificationStatus === "PENDING"
            // ) {
            //   this.resendEmploymentOtp().then(() => {
            //     this.showOtp = true;
            //     this.loading = false;
            //   });
            // } else {
            //   this.loading = false;
            // }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getWorkEmail(id) {
        // this.setLoading(true);
        GET_WORK_EMAIL(id)
          .then((res) => {
            this.workEmail = res.data.data.workEmail;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getWorkDocuments(id) {
        // this.setLoading(true);
        GET_WORK_EMPLOYMENT_DOCUMENTS(id)
          .then((res) => {
            this.documents = res.data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      submitOtp() {
        this.otpLoading = true;
        SUBMIT_EMPLOYMENT_OTP(this.otp)
          .then((res) => {
            this.otpLoading = false;
            this.showOtp = false;
            this.hasSubmittedOtp = true;
            setTimeout(() => {
              this.$emit("next");
            }, 300);
          })
          .catch(() => {
            this.otpLoading = false;
          });
      },
      handleOtpComplete() {
        this.showOtp = false;
        this.getEmploymentInfo();
        this.disableInput = true;
      },
      close() {
        this.updateWorkInfo = false;
        // this.getEmploymentInfo();
      },
      proceed() {
        this.showVerificationModal = false;
        this.updateWorkInfo = true;
      },
      updateEmploymentEmailDetails() {
        this.updateWorkInfo = false;
        this.showAlert({
          display: true,
          description: "Employment details successfully updated",
          type: "success",
          title: "Success",
        });
        this.getEmploymentInfo();
        this.getDashboardData()
      },
      updateEmploymentDocumentDetails() {
        this.updateWorkInfo = false;
        this.showAlert({
          display: true,
          description:
            "Employment details successfully updated. Pending approval",
          type: "success",
          title: "Success",
        });
        this.getEmploymentInfo();
        this.getDashboardData()
      },
      employmentStatus(data) {
        return data?.verificationStatus?.toLowerCase();
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";

  .otp-button {
    position: absolute;
    right: 0;
    top: 0.2rem;
  }

  .spinner {
    position: absolute;
    right: 0.5rem;
    top: 0;
  }
</style>
