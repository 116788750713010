var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    [
      _c("div", { staticClass: "my-4" }, [
        _c("h4", { staticClass: "font-semibold text-lg text-black" }, [
          _vm._v(" Work Information "),
          _vm.employmentData.verificationStatus !== "APPROVED" &&
          _vm.requestMethod === "put"
            ? _c("span", { staticClass: "text-xs refunded ml-2" }, [
                _vm._v(
                  " Verification " +
                    _vm._s(_vm.employmentStatus(_vm.employmentData)) +
                    "!"
                ),
              ])
            : _vm._e(),
        ]),
        _c("p", { staticClass: "text-grey text-sm" }, [
          _vm._v("Personalize your settings"),
        ]),
      ]),
      _vm.loading
        ? _c(
            "div",
            { key: "load" },
            _vm._l(4, function (i) {
              return _c(
                "div",
                { key: i, staticClass: "mt-8" },
                [
                  _c("Skeleton", {
                    attrs: { height: "0.8rem", width: "4rem" },
                  }),
                  _c("Skeleton", {
                    staticClass: "mt-2",
                    attrs: { width: "40%", height: "1rem" },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          key: "data",
        },
        [
          !_vm.employmentAvailable
            ? _c("EmptyState", {
                staticClass: "mt-8",
                attrs: {
                  description: "You don't have any employment data yet",
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.employmentAvailable,
                  expression: "employmentAvailable",
                },
              ],
            },
            [
              _c("transition", { attrs: { name: "slideX", mode: "out-in" } }, [
                _vm.employmentData.verificationType === "DOCUMENT" &&
                _vm.employmentData.verificationStatus === "PENDING"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "py-2 text-center my-3 mx-auto rounded-lg text-sm w-full md:w-6/12",
                        staticStyle: {
                          color: "#523f14",
                          background: "#fff0cc",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v("We are still verifying your document(s)"),
                        ]),
                      ]
                    )
                  : _vm.employmentData.verificationType === "DOCUMENT" &&
                    _vm.employmentData.verificationStatus === "DECLINED"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "p-2 text-center my-3 mx-auto rounded-lg text-sm w-full md:w-10/12",
                        staticStyle: {
                          color: "#ff5454",
                          background: "#ffe8e8",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.documents.refusalReason)),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "mt-8 md:grid grid-cols-3 py-2" }, [
                _c("p", { staticClass: "text-grey text-sm" }, [
                  _vm._v("PLACE OF WORK"),
                ]),
                _c("p", { staticClass: "capitalize" }, [
                  _vm._v(" " + _vm._s(_vm.employmentData.employerName) + " "),
                ]),
              ]),
              _c("Hr"),
              _c(
                "div",
                [
                  _vm.employmentData.verificationType === "WORK_EMAIL"
                    ? _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
                        _c("p", { staticClass: "text-grey text-sm" }, [
                          _vm._v("WORK EMAIL"),
                        ]),
                        _c("p", { staticClass: "lowercase" }, [
                          _vm._v(" " + _vm._s(_vm.workEmail) + " "),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.employmentData.verificationType === "WORK_EMAIL"
                    ? _c("Hr")
                    : _vm._e(),
                  _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
                    _c("p", { staticClass: "text-grey text-sm" }, [
                      _vm._v("WORK ADRRESS"),
                    ]),
                    _c("p", { staticClass: "capitalize" }, [
                      _vm._v(
                        " " + _vm._s(_vm.employmentData.officeAddress) + " "
                      ),
                    ]),
                  ]),
                  _c("Hr"),
                  _vm.employmentData.additionalAddressDetails
                    ? _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
                        _c("p", { staticClass: "text-grey text-sm" }, [
                          _vm._v("MORE DETAILS"),
                        ]),
                        _c("p", { staticClass: "capitalize" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.employmentData.additionalAddressDetails
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.employmentData.additionalAddressDetails
                    ? _c("Hr")
                    : _vm._e(),
                  _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
                    _c("p", { staticClass: "text-grey text-sm" }, [
                      _vm._v("STATE"),
                    ]),
                    _c("p", { staticClass: "capitalize" }, [
                      _vm._v(" " + _vm._s(_vm.employmentData.state) + " "),
                    ]),
                  ]),
                  _c("Hr"),
                  _c("div", { staticClass: "md:grid grid-cols-3 py-2" }, [
                    _c("p", { staticClass: "text-grey text-sm" }, [
                      _vm._v("LGA"),
                    ]),
                    _c("p", { staticClass: "capitalize" }, [
                      _vm._v(" " + _vm._s(_vm.employmentData.lga) + " "),
                    ]),
                  ]),
                  _c("Hr"),
                  _vm.employmentData.verificationType === "DOCUMENT"
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.documents.documents,
                          function (document, index) {
                            return _c(
                              "div",
                              {
                                key: document.id,
                                staticClass: "md:grid grid-cols-3 py-2",
                              },
                              [
                                _c("p", { staticClass: "text-grey text-sm" }, [
                                  _vm._v("DOCUMENT #" + _vm._s(index + 1)),
                                ]),
                                _c("p", { staticClass: "capitalize" }, [
                                  _vm._v(" " + _vm._s(document.type) + " "),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex justify-end mt-3" },
            [
              _c("SecondaryButton", {
                attrs: {
                  color: "text-white",
                  background: "bg-brandPurple",
                  fontSize: "text-sm",
                  text: _vm.employmentAvailable
                    ? "Change work info"
                    : "Add work Info",
                  loading: _vm.updateLoading,
                },
                on: {
                  click: function ($event) {
                    _vm.employmentAvailable
                      ? (_vm.showVerificationModal = true)
                      : _vm.proceed()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("EmploymentOtp", {
        attrs: { display: _vm.showOtp },
        on: {
          close: function ($event) {
            _vm.showOtp = false
          },
          completed: _vm.handleOtpComplete,
        },
      }),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showVerificationModal },
          on: {
            close: function ($event) {
              _vm.showVerificationModal = false
            },
          },
        },
        [
          _c(
            "h2",
            { staticClass: "-mt-6 text-xl font-bold primary-text w-10/12" },
            [_vm._v(" Do you want to change your work info? ")]
          ),
          _c("p", { staticClass: "text-grey py-3" }, [
            _vm._v(
              " This action will require you to input your work information again. "
            ),
          ]),
          _c("Button", {
            staticClass: "mt-6 place-self-end",
            attrs: {
              text: "Yes, proceed",
              shadow: true,
              width: "w-full",
              p: "px-3 py-4",
            },
            on: {
              click: function ($event) {
                return _vm.proceed()
              },
            },
          }),
          _c("SecondaryButton", {
            staticClass: "mt-2 place-self-end font-semibold",
            attrs: {
              text: "Cancel",
              width: "w-full",
              p: "px-3 py-4",
              background: "bg-transparent",
              color: "text-dark",
              fontSize: "text-base",
            },
            on: {
              click: function ($event) {
                _vm.showVerificationModal = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "LargeModal",
        {
          attrs: {
            size: "large",
            display: _vm.updateWorkInfo,
            background: "#ffffff",
            padding: "px-6 pt-6",
          },
          on: { close: _vm.close },
        },
        [
          _vm.updateWorkInfo
            ? _c("UpdateWorkInfo", {
                on: {
                  email: _vm.updateEmploymentEmailDetails,
                  close: _vm.close,
                  documents: _vm.updateEmploymentDocumentDetails,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }